<template>
	<!-- 排号屏手机版本 -->
	<div class="wrap">
		<div class="time">
			<span>
				当前时间:{{time}}
			</span>
			<span>
				最大载客量：{{maxNumber}}
			</span>
		</div>
		
		<div class="area">
			<div class="area-address">
				{{config.companyName}}
				<span>{{self.projectName}}</span> 
				（{{selfAdd||self.projectRemark}}）
			</div>
			
			<div class="area-number">
				<p>{{self.autoTitle}}</p>
				<p>{{self.showStart}}-{{self.showEnd}}</p>
			</div>
			
			<div class="area-time">
				<p>今日余票：{{self.batchHasNum}}</p>
				<p v-if="config2.showNumberToPhone=='1'">已排号至：{{self.sortTotalCapacity}}</p>
				<p v-if="config2.showEntryTimePhone=='1'">预计排队进入时间：{{self.sortTotalTime}}</p>
			</div>
		</div>
		
		<div class="area">
			<div class="area-address">
				{{config.companyName}}
				<span>{{other.projectName}}</span> 
				（{{otherAdd||other.projectRemark}}）
			</div>
			
			<div class="area-number">
				<p>{{other.autoTitle}}</p>
				<p>{{other.showStart}}-{{other.showEnd}}</p>
			</div>
			
			<div class="area-time">
				<p>今日余票：{{other.batchHasNum}}</p>
				<p v-if="config2.showNumberToPhone=='1'">已排号至：{{other.sortTotalCapacity}}</p>
				<p v-if="config2.showEntryTimePhone=='1'">预计排队进入时间：{{other.sortTotalTime}}</p>
			</div>
		</div>
		
		<div class="tips" v-html="screenTipPhone">
		</div>
		
		<div v-if="errMsg" style="margin-top: 2vh;color: #ff0000;">
			错误提示：{{errMsg}}
		</div>
	</div>
</template>

<script setup>
import { ref,onBeforeUnmount} from 'vue'
import $request from '../assets/js/request.js'
//---获取排队信息
let config=''
let config2=''
let token=''
if(sessionStorage.config){
  config=JSON.parse(sessionStorage.config)//登录缓存的本地信息
  token=config.token
  config2=config.config
  if(config2.showEntryTimePhone){
	  config2.showEntryTimePhone=parseInt(config2.showEntryTimePhone)
  }
  if(config2.showNumberToPhone){
	  config2.showNumberToPhone=parseInt(config2.showNumberToPhone)
  }
  if(config2.showScanningCode){
	  config2.showScanningCode=parseInt(config2.showScanningCode)
  }
}
let timer=''
let errMsg=ref('')
let maxNumber=ref(0)//最大购买数量
let screenTipPhone=ref('')//配置信息
let self=ref({})//本站点信息
let other=ref({})//其他站点信息
let selfAdd=ref('')//本站地址
let otherAdd=ref('')//对面地址
let data={
  companyId:config.companyId
}
function getSort(){
  $request('tbd-product/sort/scene/batchQuery?token='+token, data).then((res) => {
    if (res.code == '00') {
      let sortInfo=res.data
      //站点Id
      let projectId1=res.data[0].projectId
      let projectId2=res.data[1].projectId
      screenTipPhone.value=res.main[projectId1].projectExtend.screenTipPhone||''
      selfAdd.value=res.main[projectId1].projectExtend.projectAddress||''
      otherAdd.value=res.main[projectId2].projectExtend.projectAddress||''
      maxNumber=0
      sortInfo.forEach(item=>{
        maxNumber+=item.batchDisplayNum
        item.autoTitle = item.showEnd >= item.sortTotalCapacity ? '请进入等候区' : '正进入验号区号段'
        item.color = item.showEnd >= item.sortTotalCapacity ? '#ff0000' : '#ffffff'
      })
      self.value=sortInfo[0]
      other.value=sortInfo[1]
    }else{
      errMsg.value=res.message
    } 	
  }).catch(()=>{
    errMsg.value='请求失败'
  })
  timer=setTimeout(getSort,1000*30)
  onBeforeUnmount(()=>{
    clearTimeout(timer)
  })
}
getSort()
//---获取时间
let time=ref('') //倒计时
function nextTime() {
  time.value=new Date().Format('hh:mm:ss')
  setTimeout(nextTime, 1000)
}
nextTime()
</script>

<style lang="scss" scoped>
	.wrap{
		min-height: 100%;
		background:url('../assets/image/suodao.jpg') no-repeat;
		font-size: 14px;
		padding: 4vh 12px;
		background-size: 100% 100%;
	}
	.time{
		background: #ffffff;
		border-radius: 10px;
		padding: 15px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.area{
		margin-top: 3vh;
		background: #ffffff;
		border-radius: 10px;
		padding: 20px;
	}
	.area-address{
		font-weight: bold;
		span{
			color: #ff0000;
			font-size: 16px;
		}
	}
	.area-number{
		font-weight: bold;
		font-size: 16px;
		text-align: center;
		margin-top: 4vh;
		color: #ff0000;
		p:last-child{
			font-size: 30px;
		}
	}
	.area-time{
		margin-top: 4vh;
	}
	.tips{
		margin-top: 3vh;
		line-height: 28px;
		color: #ffffff;
	}
</style>